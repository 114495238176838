<template>
  <base-view title="Panel de control" icon="settings">
    <!-- Muestra el periodo fiscal activo -->
    <template #right-place>
      <div class="row">
        <x-periodo-fiscal-tag classBody="col-4 offset-8 right-content" variant="primary" />
      </div>
    </template>
    <check-authorization :requiresRoles="['admin']">
      <panel-de-control></panel-de-control>
    </check-authorization>
  </base-view>
</template>

<script>
import PanelDeControl from '@/components/PanelDeControl/PanelDeControl'

export default {
  name: 'PanelDeControlView',

  components: {
    PanelDeControl
  }
}
</script>
