<template>
  <div v-if="secciones.length > 0">
    <div
      class="card card-header-actions mb-4"
      v-for="(seccion, index) in secciones"
      :key="seccion.id"
    >
      <div class="card-header">
        {{ seccion[0].titulo }}
      </div>
      <div class="card-body">
        <b-row>
          <b-col
            cols="12"
            :md="getColumns(seccion.length)"
            :lg="getColumns(seccion.length)"
            v-for="propiedad in seccion"
            :key="propiedad.id"
          >
            <b-form-group>
              <template #label>
                <label class="small font-weight-bolder">
                  {{ propiedad.nombre }}
                </label>
              </template>
              <b-form-select
                size="lg"
                :value="propiedad.valor"
                :multiple='propiedad.multiple === "true"'
                :options="getListToSelects(modulos[index])"
                @input="update(propiedad.id, $event)"
              ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
  <loading class="card-body center" v-else></loading>
</template>

<script>
import { EventBus } from '@/EventBus'
import Resource from '@/api/Resource'
import _ from 'lodash'

const panelDeControl = new Resource('/api/panel-de-control', 'PanelDeControl')

export default {
  name: 'PanelDeControl',

  data () {
    return {
      secciones: [],

      modulos: []
    }
  },

  async created () {
    const { error, data, message } = await panelDeControl.all()
    if (error) return this.$notify({ error, message }, 'Panel de control')

    const periodoActivo = await this.$getPeriodoFiscalActivo()

    // Clasificamos las opciones del panel de control para guardarlas de esa forma en
    // un arreglo de secciones
    const x = _.groupBy(data.data, 'modulo_front_end')
    for (const key in x) {
      if (Object.hasOwnProperty.call(x, key)) {
        const propiedad = x[key]
        this.secciones.push(propiedad)

        // Ejecutamos el modulo correspondiente para llamar las posibles opciones
        // de configuracion
        const nombreModulo = propiedad[0].modulo_front_end
        const { data } = await this.$store.dispatch(`${nombreModulo}`, periodoActivo.id)
        this.modulos.push(data)
      }
    }
  },

  methods: {
    getListToSelects (list) {
      if (!list) return []

      return list.map(f => ({ value: f.clave || f.nombre, text: f.nombre }))
    },

    async update (id, event) {
      const res = await panelDeControl.update(id, {
        valor: event
      })

      EventBus.$emit('toast', 'Panel de control', res.data.message, {
        status: 'success'
      })
    },

    getColumns (length) {
      return length > 1 ? 6 : 12
    }
  }
}
</script>

<style></style>
